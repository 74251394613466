import createCookie from "../utils/createCookie";
import getParameterByName from "../utils/getParameterByName";

function init() {
    if (getParameterByName("MOC")) createCookie("Dupixent-MOC", getParameterByName("MOC"));

    var indication = document.body.dataset.indication;
    createCookie("indication", indication);
}

export default { init };
