import scrollToElement from "../utils/scrollToElement";
import webGalleryScroll from "./webGalleryScroll";
import onScrollTranscriptHcp from "../modules/onScrollTranscriptHcp";
import DOMPurify from "dompurify";

function init() {
    //set first videos in player to active
    $(".first").addClass("active");

    videoJS();
    onScrollTranscriptHcp.init();
}

function getChapter(elem, player) {
    let chapters = $($(elem).data("chapters-container"));

    //check for chapters container next to video
    if (!chapters.length) return false;

    player.ready(function() {
        //chapters demo
        let chapterTT = [].filter.call(player.remoteTextTracks(), function(tt) {
            return tt.kind === "chapters";
        });
        if (chapterTT.length === 0) {
            return;
        }
        let cuesAra = chapterTT[0].cues;

        //check if metadata type is chapters

        //empty chapters container
        chapters.html("");
        //loop through chapter metadata
        for (var i = 0; i < cuesAra.length; i++) {
            var cue = cuesAra[i],
                // eslint-disable-next-line no-undef
                $el = $("<li>" + cue.text + "</li>");
            if (i == 0) $el.addClass("active");
            //create chapter elements
            $el.data("time", cue.startTime)
                .on("click", $.proxy(handleChapterChange, $el, player))
                .appendTo(chapters);
            cue.el = $el;
            player.remoteTextTracks()[0].addEventListener("cuechange", $.proxy(updateChapters, cue, player));
        }
    });

    // eslint-disable-next-line no-inner-declarations
    function updateChapters(player) {
        let currentTime = player.currentTime();

        (this.el, this.startTime <= currentTime && currentTime < this.endTime) ? this.el.addClass("active") : this.el.removeClass("active");
    }

    // eslint-disable-next-line no-inner-declarations
    function handleChapterChange(player) {
        player.currentTime($(this).data("time"));
    }
}

function videoJS() {
    // eslint-disable-next-line no-undef
    if (window.videojs) {
        $("video-js, .video-js").each(function() {
            // eslint-disable-next-line no-undef
            var player = videojs(this);

            //If video js already has attribute 'muted', keep it muted. Else, initialize the video unmuted
            if (this.getAttributeNames().includes("muted")) {
                player.muted(true);
            } else {
                player.muted(false);
            }

            getChapter(this, player);

            player.bigPlayButton.on(["click", "touchstart"], () => {
                player.ready(() => {
                    if (
                        $(this)
                            .parent()
                            .find(".transcriptToggle")
                    ) {
                        $(this)
                            .parent()
                            .find(".transcriptToggle")
                            .addClass("js-expanded")
                            .children()
                            .toggleClass("hidden");
                    }
                    $(this)
                        .parent()
                        .find(".transcript")
                        .removeClass("hidden");
                });
            });

            this.addEventListener("click", () => {
                pauseOtherVideos(player);
            });
        });
    }

    $(".instruction-opener, .section-instruction-grid .collapsible__list a").on("click", function(e) {
        e.preventDefault();
        $(".section-instruction-media").removeClass("invisible");
        $(".section-instruction-grid").addClass("invisible");

        var videoLink = $('.video-link[href="' + $(this).attr("href") + '"]');
        var paneWrapper = videoLink.parents(".pane-wrapper");

        videoLink.trigger("click", false);

        if (!videoLink.position()) {
            return;
        }
        paneWrapper.scrollTop(videoLink.position().top);
    });

    $("[data-instruction-set]").on("click", function() {
        var instructionID = $(this).data("instruction-set");
        $(`[data-target="${instructionID}"]`).tab("show");
    });

    let url = new URL(DOMPurify.sanitize(document.location));
    let hash = url.hash;

    $('.instruction-opener[href="' + hash + '"]').trigger("click");

    //Show id depending on hash
    let videoAnchors = document.querySelectorAll("contentVideoGallery [data-id-anchor]");
    let hashArray = [];
    if (hash !== "") {
        videoAnchors.forEach(anchor => {
            let attr = anchor.dataset.idAnchor.toLowerCase();

            hashArray.push(attr);

            //uncomment for debugging
            //console.log(attr, hash.toLowerCase()); //these should match

            if (hash.toLowerCase() === attr) {
                //remove first active
                let firstChild = anchor.closest("ul");
                firstChild.querySelector(".first").classList.remove("active");
                //set li to active class
                anchor.parentNode.classList.add("active");

                let videoId = anchor.dataset.videoId;
                let playerId = anchor.dataset.videoPlayerId;
                // eslint-disable-next-line no-undef
                let player = videojs(playerId);
                player.catalog.getVideo(videoId, function(error, video) {
                    player.catalog.load(video);
                    let playPromise = player.play();
                    playPromise.then(
                        () => {
                            getChapter(document.querySelector(playerId), player);
                            player.muted(false);
                        },
                        () => {
                            getChapter(document.querySelector(playerId), player);
                            player.muted(false);
                        }
                    );
                    getChapter(document.querySelector(playerId), player);
                    webGalleryScroll(document.querySelector(playerId));
                });
            }
        });
    }

    // eslint-disable-next-line no-undef
    if (document.querySelector("video-js, .video-js")) {
        $(".video-link").on("click", function(e) {
            e.preventDefault();
            var videoId = $(e.currentTarget).data("video-id"),
                playerId = $(e.currentTarget).data("video-player-id");
            // eslint-disable-next-line no-undef
            let player = videojs(document.querySelector(playerId));

            player.catalog.getVideo(videoId, function(error, video) {
                player.catalog.load(video);
                let playPromise = player.play();
                playPromise.then(
                    () => {
                        getChapter(document.querySelector(playerId), player);
                        player.muted(false);
                    },
                    () => {
                        getChapter(document.querySelector(playerId), player);
                        player.muted(false);
                    }
                );
            });
            pauseOtherVideos(player);
            scrollToElement(playerId);
        });
    }

    $("[data-doc-target]").click(function() {
        $(".docs").hide();
        $("." + $(this).data("doc-target")).show();
    });
    // Ensure we dont sniff (add event listeners to) isi hash
    let $elem = $(hash);
    if ($elem && $elem != "#isi" && !hashArray.includes($elem)) {
        //check if element exists and only collapse if it's a video
        if ($elem.length !== 0 && $elem.parents(".gallery-wrapper").length) {
            $elem.collapse("show");
        }
        if ($elem.parents(".faq-item").length) {
            scrollToElement($elem.parents(".faq-item"));
        }
    }
    videoPause();
}

function pauseOtherVideos(curPlayer) {
    $(".video-js").each(function() {
        // eslint-disable-next-line no-undef
        let player = videojs(this);
        if (player.id() !== curPlayer.id()) {
            player.pause();
        }
    });
}

function videoPause() {
    $("#pause button").on("click", function(e) {
        e.preventDefault();
        // eslint-disable-next-line no-undef
        var player = videojs.getPlayer($(".bg-video:visible")[0]);

        if (player.paused()) {
            player.play();
            $("body").removeClass("bg-video-manually-paused");
            $("#playBtn").addClass("hidden");
            $("#pauseBtn").removeClass("hidden");
        } else {
            player.pause();
            $("body").addClass("bg-video-manually-paused");
            $("#playBtn").removeClass("hidden");
            $("#pauseBtn").addClass("hidden");
        }
    });
}

export default { init };
