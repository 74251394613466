import formQuestionsAreValid from "./formQuestionsAreValid";
import postData from "./postData";
import { load } from "recaptcha-v3";
function sendFormData(b, g, c) {
    var i = {};
    if (formQuestionsAreValid(g, c)) {
        $(".FormControllerClass")
            .find("div[data-questionid]")
            .each(function(n, m) {
                var l = $(m),
                    p = $(this).attr("data-question"),
                    j = "",
                    q = "",
                    r = "",
                    o = "",
                    k = [];
                switch ($(this).attr("data-type")) {
                    case "FormTextBox":
                        $(this)
                            .find(":input:not([type=hidden])")
                            .each(function() {
                                if (
                                    $(this)
                                        .val()
                                        .trim() !== ""
                                ) {
                                    j = $(this)
                                        .val()
                                        .trim();
                                }
                            });
                        break;
                    case "FormTextArea":
                        $(this)
                            .find("textarea")
                            .each(function() {
                                if (
                                    $(this)
                                        .val()
                                        .trim() !== ""
                                ) {
                                    j = $(this)
                                        .val()
                                        .trim();
                                }
                            });
                        break;
                    case "PasswordTextBox":
                        $(this)
                            .find(":input:not([type=hidden])")
                            .each(function() {
                                if (
                                    $(this)
                                        .val()
                                        .trim() !== ""
                                ) {
                                    j = $(this)
                                        .val()
                                        .trim();
                                }
                            });
                        break;
                    case "FormCheckbox":
                        $(this)
                            .find(":input:not([type=hidden])")
                            .each(function() {
                                if ($(this).is(":checkbox")) {
                                    if ($(this).is(":checked")) {
                                        j = $(this).attr("data-anwserId");
                                    }
                                }
                            });
                        break;
                    case "FormDropdownList":
                        m = $(this).find(":selected");
                        if (m.val().trim() !== "") {
                            j = m.val().trim();
                        }
                        break;
                    case "FormRadioButton":
                        $(this)
                            .find(":input:not([type=hidden])")
                            .each(function() {
                                if ($(this).is(":checked")) {
                                    j = $(this).attr("data-anwserId");
                                }
                            });
                        break;
                    case "NewsletterCheckbox":
                        $(this)
                            .find(":input")
                            .each(function() {
                                if ($(this).is(":checkbox")) {
                                    if ($(this).is(":checked")) {
                                        k.push($(this).attr("data-anwserId"));
                                    }
                                }
                            });
                        break;
                    default:
                        console.log("Unknown question data type: " + $(this).attr("data-type")); //eslint-disable-line no-console
                }
                if (k.length > 0) {
                    jQuery.each(k, function(t, s) {
                        if (t == 0) {
                            i[p] = s;
                        } else {
                            i[p] = i[p] + "|" + s;
                        }
                    });
                } else {
                    i[p] = j;
                }
                o = $(l).find("input[data-tagkey]");
                $.each(o, function(s, t) {
                    q = $(t).attr("data-tagkey");
                    r = $(t).attr("data-tagkey-value");
                    i[q] = r;
                });
            });
        var recaptchaV3String = $("button[name='recaptcha']").val();
        var recaptchaV3Boolean = recaptchaV3String === "true";

        if (recaptchaV3Boolean) {
            const recaptchaID = document.querySelector(".btn-hcp-request-a-rep.submit").dataset.sitekey;
            load(recaptchaID).then(recaptcha => {
                recaptcha.execute("submit").then(token => {
                    postData(token, recaptchaV3Boolean, i);
                });
            });
        } else {
            let token = false;
            postData(token, recaptchaV3Boolean, i);
        }
    } else {
        b.preventDefault();
        $("#generalError").removeAttr("hidden");
        $("html, body").animate({ scrollTop: 0 }, 800);
    }
}

export default sendFormData;
