let scrollGradient = () => {
    return {
        hideGradient: false,
        detectScrollEnd() {
            this.hideGradient = this.$el.scrollTop >= this.$el.scrollHeight - this.$el.offsetHeight;
        }
    };
};

export default scrollGradient;
