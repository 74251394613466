import { validateFormTextBox, validateFormTextArea, validateFormCheckbox, validateFormDropdownlist, validateFormRadioButton, validateFormFile } from "./validations";

function formQuestionsAreValid(c, a) {
    var b = true;
    $(".anwser").each(function() {
        if ($(this).attr("data-required") == "true") {
            switch ($(this).attr("data-type")) {
                case "FormTextBox":
                    if (validateFormTextBox($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
                case "PasswordTextBox":
                    if (validateFormTextBox($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
                case "FormTextArea":
                    if (validateFormTextArea($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
                case "FormCheckbox":
                    if (validateFormCheckbox($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
                case "FormDropdownList":
                    if (validateFormDropdownlist($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
                case "FormRadioButton":
                    if (validateFormRadioButton($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
                case "FormFile":
                    if (validateFormFile($(this), c, a) & b) {
                        b = true;
                    } else {
                        b = false;
                    }
                    break;
            }
            //isZipValid???
            // if (b && isZipValid) {
            //     b = true;
            // } else {
            //     b = false;
            // }
        }
    });
    return b;
}
export default formQuestionsAreValid;
