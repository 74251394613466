/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
import Player from "@vimeo/player";
import { trackEvent } from "../../track";
import DOMPurify from "dompurify";

let vimeoPlayer = playerOptions => {
    return {
        showTranscript: false,
        chapters: [],
        text: {},
        linkedVideo: null,
        playerOptions: {
            autoplay: playerOptions?.autoplay || false,
            muted: playerOptions?.muted || false,
            loop: playerOptions?.loop || false,
            controls: playerOptions?.controls || true,
            progress_bar: playerOptions?.progress_bar || true,
            ...(playerOptions?.url && { url: playerOptions.url })
        },
        activeChapter: null,
        showPoster: true,
        //player states - unplayed, playing, paused, ended
        playerState: "unplayed",
        player: null,
        //initialize player
        init() {
            const url = new URL(DOMPurify.sanitize(document.location));
            const hash = decodeURI(url.hash);
            if (hash !== "") {
                //Show id depending on hash
                const anchor = document.querySelector(`[data-id-anchor="${hash}"]`);
                if (anchor) {
                    this.linkedVideo = anchor.dataset.vimeoLink;

                    if (this.$refs.filterGalleryForm) {
                        anchor.click();
                        this.text.title = anchor.querySelector(".video-title")?.innerHTML;
                        this.text.description = anchor.querySelector(".video-description")?.innerHTML;
                    }

                    if (this.$refs.videoContainer) {
                        this.showPoster = false;
                        this.$refs.videoContainer.scrollIntoView({ behavior: "smooth", block: "center" });
                    }
                }
            }
        },
        initPlayer(url) {
            if (url) this.playerOptions.url = url;
            this.activeChapter = null;
            const player = new Player(this.$refs.vimeoIframe, this.playerOptions);
            this.getChapters(player);
            this.handleEvents(player);
            this.player = player;
        },
        //destroyPlayer
        destroyPlayer() {
            const player = new Player(this.$refs.vimeoIframe);
            player.destroy();
            this.playerState = "unplayed";
        },
        //play video
        play() {
            this.getPlayer().play();
            this.playerState = "played";
        },
        togglePlay() {
            const player = this.getPlayer();

            player.getPaused().then(function(paused) {
                if (paused) {
                    player.play();
                } else {
                    player.pause();
                }
            });
        },
        loadVideo(url, play) {
            if (url) this.playerOptions.url = url;
            const player = this.getPlayer();
            this.handleEvents(player);

            this.getPlayer()
                .loadVideo(this.playerOptions.url)
                .then(id => {
                    this.getChapters(player);
                    this.handleEvents(player);

                    if (play) {
                        this.play();
                    }
                });
        },
        unloadVideo() {
            this.getPlayer()
                .unload()
                .then(() => {
                    this.playerState = "unplayed";
                });
        },
        pause() {
            this.getPlayer().pause();
        },
        //get chapters
        getChapters(player) {
            player.getChapters().then(chapters => {
                this.chapters = chapters;
                this.watchChapterChange(player);
            });
        },
        playByTime(time) {
            const player = new Player(this.$refs.vimeoIframe);
            player.setCurrentTime(time);
        },
        watchChapterChange(player) {
            player.on("chapterchange", () => {
                player.getCurrentChapter().then(chapter => {
                    this.activeChapter = chapter;
                });
            });
        },
        audioTimeMath(time) {
            let minutes = Math.floor(time / 60);
            let seconds = String(`${time % 60}`).padStart(2, "0");
            return `${minutes}:${seconds}`;
        },
        getPlayer() {
            return Alpine.raw(this.player);
        },
        handleEvents(player) {
            const self = this;
            const percentLogger = [];
            player.off("play");
            player.off("pause");
            player.off("ended");
            player.off("timeupdate");

            player.getVideoId().then(videoId => {
                player.on("play", () => {
                    self.playerState = "playing";
                    trackEvent({
                        event: "video",
                        gaVideoEventCategory: "Video",
                        gaVideoEventAction: "Video Play",
                        gaVideoEventLabel: videoId
                    });
                });
                player.on("pause", () => {
                    if (self.playerState == "playing") {
                        self.playerState = "paused";
                    }
                    trackEvent({
                        event: "video",
                        gaVideoEventCategory: "Video",
                        gaVideoEventAction: "Pause",
                        gaVideoEventLabel: videoId
                    });
                });
                player.on("ended", () => {
                    self.playerState = "ended";
                    trackEvent({
                        event: "video",
                        gaVideoEventCategory: "Video",
                        gaVideoEventAction: "Video Complete",
                        gaVideoEventLabel: videoId
                    });
                });
                player.on("timeupdate", function({ percent }) {
                    const currPercent = Math.floor(percent * 100);
                    if (currPercent > 0 && currPercent % 10 === 0 && !percentLogger.includes(currPercent)) {
                        percentLogger.push(currPercent);
                        trackEvent({
                            event: "video",
                            gaVideoEventCategory: "Video",
                            gaVideoEventAction: `Video Progress - ${currPercent}%`,
                            gaVideoEventLabel: videoId
                        });
                    }
                });
            });
        }
    };
};

export default vimeoPlayer;
