class PreFilledPen {
    constructor() {
        this.prefilledPen = document.querySelector("[data-prefilled-pen]");
        this.init();
    }

    init() {
        const $this = this;
        if ($this.prefilledPen) {
            const parentScore = $this.prefilledPen.closest(".score-container");
            parentScore.classList.add("pfp-cta");
            if ($this.prefilledPen.classList.contains("gradient-bg")) {
                parentScore.classList.add("gradient-bg");
            }
        }
    }
}

export default PreFilledPen;
