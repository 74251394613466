import findConfirmationField from "./findConfirmationField";

var mandatoryLabelClass = "anwser-mandatory-label";
var selectStyleElement = "select";
var errorClass = "hasError";
export function validateFormTextBoxOnFocusOut(b) {
    let a = b.parents(".anwser");
    validateFormTextBox(a, "0", "0");
}

export function validateFormTextAreaOnFocusOut(b) {
    let a = b.parents(".anwser");
    validateFormTextArea(a, "0", "0");
}

export function validateFormDate(c) {
    let d = true;
    let a = c.parents(".anwser");
    if (a.attr("data-required") == "true") {
        if (c.val() == "") {
            let b = "errorLb" + c.attr("data-index");
            $("#" + b).removeAttr("hidden");
            d = false;
        } else {
            let b = "errorLb" + c.attr("data-index");
            $("#" + b).attr("hidden", true);
        }
    }
    if (d) {
        c.removeClass(errorClass);
    } else {
        c.addClass(errorClass);
    }
    return d;
}

export function validateFormDropdownlistOnchange(b) {
    let a = b.parents(".anwser");
    validateFormDropdownlist(a, "0", "0");
}

export function validateFormCheckboxOnchange(b) {
    let a = b.parents(".anwser");
    validateFormCheckbox(a, "0", "0");
}

export function validateFormRadioButtonOnchange(b) {
    if (b.attr("data-anwser") == "Other" || b.attr("data-anwser") == "Others") {
        $("#otherinputbox").css("display", "block");
    } else {
        $("#otherinputbox").css("display", "none");
    }
    let a = b.parents(".anwser");
    validateFormRadioButton(a, "0", "0");
}

export function validateFormTextBox(a, g, b) {
    let j = a.find(":input");
    if (j.hasClass("txtDate")) {
        return validateFormDate(j);
    }
    let f = true;
    let c = "";
    if (b == "1") {
        c = j.attr("data-formid");
        if (c == g) {
            c = c + "_";
            if (a.attr("data-validate") == "true") {
                if (j.val().trim() != "") {
                    let i = a.attr("data-regex");
                    let h = new RegExp(i);
                    if (!h.test(j.val().trim())) {
                        f = false;
                        let d = c + "validateLb" + j.attr("data-index");
                        $("#" + d).removeAttr("hidden");
                        $("#" + d).removeClass("anwser label");
                        $("#" + d).addClass(mandatoryLabelClass);
                    } else {
                        let d = c + "validateLb" + a.attr("data-index");
                        $("#" + d).attr("hidden", j);
                        $("#" + d).removeClass(mandatoryLabelClass);
                    }
                } else {
                    let d = c + "validateLb" + a.attr("data-index");
                    $("#" + d).attr("hidden", j);
                    $("#" + d).removeClass(mandatoryLabelClass);
                }
            }
            if (j.attr("data-confirmation") != undefined) {
                if (findConfirmationField(j.attr("data-confirmation"), j.val().trim(), a.attr("data-questionid"))) {
                    let d = c + "confirmLb" + a.attr("data-index");
                    $("#" + d).attr("hidden", j);
                    $("#" + d).removeClass(mandatoryLabelClass);
                } else {
                    f = false;
                    let d = c + "confirmLb" + j.attr("data-index");
                    $("#" + d).removeAttr("hidden");
                    $("#" + d).removeClass("anwser label");
                    $("#" + d).addClass(mandatoryLabelClass);
                }
            }
            if (a.attr("data-required") == "true") {
                if (j.val().trim() == "") {
                    f = false;
                    let d = c + "errorLb" + a.attr("data-index");
                    $("#" + d).removeAttr("hidden");
                    $("#" + d).removeClass("anwser label");
                    $("#" + d).addClass(mandatoryLabelClass);
                } else {
                    let d = c + "errorLb" + j.attr("data-index");
                    $("#" + d).attr("hidden", true);
                    $("#" + d).removeClass(mandatoryLabelClass);
                }
            }
            if (f) {
                j.removeClass(errorClass);
            } else {
                j.addClass(errorClass);
            }
        }
    } else {
        if (a.attr("data-validate") == "true") {
            if (j.val().trim() != "") {
                let i = a.attr("data-regex");
                let h = new RegExp(i);
                if (!h.test(j.val().trim())) {
                    f = false;
                    let d = "validateLb" + j.attr("data-index");
                    $("#" + d).siblings("error");
                    $("#" + d).removeAttr("hidden");
                    $("#" + d).removeClass("anwser label");
                    $("#" + d).addClass(mandatoryLabelClass);
                } else {
                    let d = "validateLb" + a.attr("data-index");
                    $("#" + d).attr("hidden", j);
                    $("#" + d).removeClass(mandatoryLabelClass);
                }
            } else {
                let d = "validateLb" + a.attr("data-index");
                $("#" + d).attr("hidden", j);
                $("#" + d).removeClass(mandatoryLabelClass);
            }
        }
        if (j.attr("data-confirmation") != undefined) {
            if (findConfirmationField(j.attr("data-confirmation"), j.val().trim(), a.attr("data-questionid"))) {
                let d = "confirmLb" + a.attr("data-index");
                $("#" + d).attr("hidden", j);
                $("#" + d).removeClass(mandatoryLabelClass);
            } else {
                f = false;
                let d = "confirmLb" + j.attr("data-index");
                $("#" + d).removeAttr("hidden");
                $("#" + d).removeClass("anwser label");
                $("#" + d).addClass(mandatoryLabelClass);
            }
        }
        if (a.attr("data-required") == "true") {
            if (j.val().trim() == "") {
                f = false;
                let d = "errorLb" + a.attr("data-index");
                $("#" + d).removeAttr("hidden");
                $("#" + d).removeClass("anwser label");
                $("#" + d).addClass(mandatoryLabelClass);
            } else {
                if (j.is("#phone") && j.val().trim() == "(   )") {
                    f = false;
                    let d = "errorLb" + a.attr("data-index");
                    $("#" + d).removeAttr("hidden");
                    $("#" + d).removeClass("anwser label");
                    $("#" + d).addClass(mandatoryLabelClass);
                } else {
                    let d = "errorLb" + j.attr("data-index");
                    $("#" + d).attr("hidden", true);
                    $("#" + d).removeClass(mandatoryLabelClass);
                }
            }
        }
        if (f) {
            j.removeClass(errorClass);
        } else {
            j.addClass(errorClass);
        }
    }
    return f;
}

export function validateFormTextArea(a, g, b) {
    let j = a.find("textarea");
    let f = true;
    let c = "";
    if (b == "1") {
        c = j.attr("data-formid");
        if (c == g) {
            c = c + "_";
            if (a.attr("data-required") == "true") {
                if (j.val().trim() == "") {
                    f = false;
                    let d = c + "errorLb" + j.attr("data-index");
                    $("#" + d).removeAttr("hidden");
                    $("#" + d).addClass(mandatoryLabelClass);
                } else {
                    let d = c + "errorLb" + j.attr("data-index");
                    $("#" + d).attr("hidden", true);
                    $("#" + d).removeClass(mandatoryLabelClass);
                }
                if (a.attr("data-validate") == "true") {
                    if (j.val().trim() != "") {
                        let i = a.attr("data-regex");
                        let h = new RegExp(i);
                        if (h.test(j.val().trim())) {
                            f = false;
                            let d = c + "validateLb" + j.attr("data-index");
                            $("#" + d).removeAttr("hidden");
                            $("#" + d).addClass(mandatoryLabelClass);
                        } else {
                            let d = c + "validateLb" + a.attr("data-index");
                            $("#" + d).attr("hidden", j);
                            $("#" + d).removeClass(mandatoryLabelClass);
                        }
                    } else {
                        let d = c + "validateLb" + a.attr("data-index");
                        $("#" + d).attr("hidden", j);
                        $("#" + d).removeClass(mandatoryLabelClass);
                    }
                }
            }
        }
    } else {
        if (a.attr("data-required") == "true") {
            let j = a.find("textarea");
            if (j.val().trim() == "") {
                f = false;
                let d = "errorLb" + j.attr("data-index");
                $("#" + d).removeAttr("hidden");
                $("#" + d).addClass(mandatoryLabelClass);
            } else {
                let d = "errorLb" + j.attr("data-index");
                $("#" + d).attr("hidden", true);
                $("#" + d).removeClass(mandatoryLabelClass);
            }
            if (a.attr("data-validate") == "true") {
                if (j.val().trim() != "") {
                    let i = a.attr("data-regex");
                    let h = new RegExp(i);
                    if (h.test(j.val().trim())) {
                        f = false;
                        let d = "validateLb" + j.attr("data-index");
                        $("#" + d).removeAttr("hidden");
                        $("#" + d).addClass(mandatoryLabelClass);
                    } else {
                        let d = "validateLb" + a.attr("data-index");
                        $("#" + d).attr("hidden", j);
                        $("#" + d).removeClass(mandatoryLabelClass);
                    }
                } else {
                    let d = "validateLb" + a.attr("data-index");
                    $("#" + d).attr("hidden", j);
                    $("#" + d).removeClass(mandatoryLabelClass);
                }
            }
        }
    }
    return f;
}

export function validateFormDropdownlist(b, h, c) {
    let a = b.find("option:selected").parent();
    let g = true;
    let d = "";
    let f = d + "errorLb" + b.attr("data-index");
    if (c == "1") {
        d = a.attr("data-formid");
        if (d == h) {
            d = d + "_";
            if (b.find("option:selected").text() == "Select" || b.find("option:selected").text() == "State" || b.find("option:selected").text() == "" || b.find("option:selected").val() == "") {
                g = false;
                $("#" + f).removeAttr("hidden");
            }
            if (g) {
                let f = d + "errorLb" + b.attr("data-index");
                $("#" + f).attr("hidden", true);
            }
            if (g) {
                $("#" + f).removeClass(mandatoryLabelClass);
            } else {
                $("#" + f).addClass(mandatoryLabelClass);
            }
        }
    } else {
        if (b.find("option:selected").text() == "Select" || b.find("option:selected").text() == "State" || b.find("option:selected").text() == "" || b.find("option:selected").val() == "") {
            g = false;
            let f = "errorLb" + b.attr("data-index");
            $("#" + f).removeAttr("hidden");
        }
        if (g) {
            let f = "errorLb" + b.attr("data-index");
            $("#" + f).attr("hidden", true);
        }
        if (g) {
            $("#" + f).removeClass(mandatoryLabelClass);
        } else {
            $("#" + f).addClass(mandatoryLabelClass);
        }
    }
    let i = b.find(selectStyleElement);
    if (g) {
        i.removeClass(errorClass);
    } else {
        i.addClass(errorClass);
    }
    return g;
}

export function validateFormCheckbox(a, h, b) {
    let i = false;
    let g = true;
    let c = "";
    if (b == "1") {
        c = a.attr("data-formid");
        if (c == h) {
            c = c + "_";
            a.find(":input").each(function() {
                if ($(this).is(":checked")) {
                    i = true;
                }
            });
            if (!i) {
                g = false;
                let d = c + "errorLb" + a.attr("data-index");
                $("#" + d).removeAttr("hidden");
                $("#" + d).addClass(mandatoryLabelClass);
            } else {
                let d = c + "errorLb" + a.attr("data-index");
                $("#" + d).attr("hidden", true);
                $("#" + d).removeClass(mandatoryLabelClass);
            }
        }
    } else {
        a.find(":input").each(function() {
            if ($(this).is(":checked")) {
                i = true;
            }
        });
        if (!i) {
            g = false;
            let d = "errorLb" + a.attr("data-index");
            $("#" + d).removeAttr("hidden");
            $("#" + d).addClass(mandatoryLabelClass);
        } else {
            let d = "errorLb" + a.attr("data-index");
            $("#" + d).attr("hidden", true);
            $("#" + d).removeClass(mandatoryLabelClass);
        }
    }
    let f = a.find(":input");
    if (g) {
        f.removeClass(errorClass);
    } else {
        f.addClass(errorClass);
    }
    return g;
}

export function validateFormRadioButton(a, h, b) {
    let i = false;
    let g = true;
    let c = "";
    if (b == "1") {
        c = a.attr("data-formid");
        if (c == h) {
            c = c + "_";
            a.find(":input").each(function() {
                if ($(this).is(":checked")) {
                    i = true;
                }
            });
            if (!i) {
                g = false;
                let d = c + "errorLb" + a.attr("data-index");
                $("#" + d).removeAttr("hidden");
                $("#" + d).addClass(mandatoryLabelClass);
            } else {
                let d = c + "errorLb" + a.attr("data-index");
                $("#" + d).attr("hidden", true);
                $("#" + d).removeClass(mandatoryLabelClass);
            }
        }
    } else {
        a.find(":input").each(function() {
            if ($(this).is(":checked")) {
                i = true;
            }
        });
        if (!i) {
            g = false;
            let d = "errorLb" + a.attr("data-index");
            $("#" + d).removeAttr("hidden");
            $("#" + d).addClass(mandatoryLabelClass);
        } else {
            let d = "errorLb" + a.attr("data-index");
            $("#" + d).attr("hidden", true);
            $("#" + d).removeClass(mandatoryLabelClass);
        }
    }
    let f = a.find(":input");
    if (g) {
        f.removeClass(errorClass);
    } else {
        f.addClass(errorClass);
    }
    return g;
}

export function validateFormFile(a, i, d) {
    let h = true;
    let b = a.find(":input");
    let f = "";
    if (d == "1") {
        f = b.attr("data-formid");
        if (f == i) {
            f = f + "_";
            if (a.attr("data-required") == "true") {
                if (b.val().trim() == "") {
                    h = false;
                    let g = f + "validateLb" + b.attr("data-index");
                    $("#" + g).removeAttr("hidden");
                    $("#" + g).addClass(mandatoryLabelClass);
                } else {
                    let g = f + "validateLb" + b.attr("data-index");
                    $("#" + g).attr("hidden", true);
                    $("#" + g).removeClass(mandatoryLabelClass);
                }
            }
            if ($(".clsRegistrationPage").length == 1) {
                h = true;
            }
            let j = true;
            if (b.val().trim() != "") {
                let c = $(b).attr("size");
                if (j && h && c != "0" && c != "") {
                    if (b[0].files[0].size / 1048576 > c) {
                        h = false;
                        let g = f + "errorLb" + b.attr("data-index");
                        $("#" + g).removeAttr("hidden");
                        $("#" + g).addClass(mandatoryLabelClass);
                    } else {
                        let g = f + "errorLb" + b.attr("data-index");
                        $("#" + g).attr("hidden", true);
                        $("#" + g).removeClass(mandatoryLabelClass);
                    }
                }
            }
            if (h) {
                b.removeClass(errorClass);
            } else {
                b.addClass(errorClass);
            }
        }
    } else {
        if (a.attr("data-required") == "true") {
            if (b.val().trim() == "") {
                h = false;
                let g = "validateLb" + b.attr("data-index");
                $("#" + g).removeAttr("hidden");
                $("#" + g).addClass(mandatoryLabelClass);
            } else {
                let g = "validateLb" + b.attr("data-index");
                $("#" + g).attr("hidden", true);
                $("#" + g).removeClass(mandatoryLabelClass);
            }
        }
        if ($(".clsRegistrationPage").length == 1) {
            h = true;
        }
        let j = true;
        if (b.val().trim() != "") {
            let c = $(b).attr("size");
            if (j && h && c != "0" && c != "") {
                if (b[0].files[0].size / 1048576 > c) {
                    h = false;
                    let g = "errorLb" + b.attr("data-index");
                    $("#" + g).removeAttr("hidden");
                    $("#" + g).addClass(mandatoryLabelClass);
                } else {
                    let g = "errorLb" + b.attr("data-index");
                    $("#" + g).attr("hidden", true);
                    $("#" + g).removeClass(mandatoryLabelClass);
                }
            }
        }
        if (h) {
            b.removeClass(errorClass);
        } else {
            b.addClass(errorClass);
        }
    }
    return h;
}

export function qValidation() {
    let a = true;
    let b = 0;
    $(".FormControllerClass .anwser").each(function() {
        if (
            $(this)
                .find(":input")
                .hasClass("hasError") ||
            $(this)
                .find("select")
                .hasClass("hasError") ||
            !$(".confirm-email-msg2").is(":hidden")
        ) {
            a = false;
        } else {
            if ($(this).attr("data-required") == "true" && a) {
                switch ($(this).attr("data-type")) {
                    case "FormTextBox":
                        if (
                            $(this)
                                .find("input")
                                .val() == ""
                        ) {
                            a = false;
                        }
                        break;
                    case "FormCheckbox":
                        $(this)
                            .find(":input")
                            .each(function() {
                                if ($(this).is(":checked")) {
                                    b++;
                                }
                            });
                        if (b == "0") {
                            a = false;
                        }
                        break;
                    case "FormDropdownList":
                        if (
                            $(this)
                                .find("option:selected")
                                .text() == "Select" ||
                            $(this)
                                .find("option:selected")
                                .text() == "State" ||
                            $(this)
                                .find("option:selected")
                                .text() == "" ||
                            $(this)
                                .find("option:selected")
                                .val() == ""
                        ) {
                            a = false;
                        } else {
                            a = true;
                        }
                        break;
                    case "FormRadioButton":
                        b = 0;
                        $(this)
                            .find(":input")
                            .each(function() {
                                if ($(this).is(":checked")) {
                                    b++;
                                }
                            });
                        if (b == "0") {
                            a = false;
                        }
                        break;
                }
                // if (a && isZipValid) {
                //     a = true;
                // } else {
                //     a = false;
                // }
            }
        }
    });
    return a;
}
