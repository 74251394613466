import chevronNav from "./chevronNav";
var $window = $(window);
function init() {
    if ($(".explore-mobile").length > 0) {
        var stickyNavTopExplore = $(".explore-mobile").offset().top - 231;
        var stickyNav = function() {
            var scrollTop = $(window).scrollTop();
            if (scrollTop > stickyNavTopExplore) {
                $(".explore-mobile").addClass("sticky");
            } else {
                $(".explore-mobile").removeClass("sticky");
            }
        };
        $window.on("resize scroll orientationchange", function() {
            stickyNav();
        });
        stickyNav();
    }
    var hasStickyNavTop = $(".mobile-nav").length > 0;

    if (hasStickyNavTop) {
        // grab the initial top offset of the navigation
        var stickyNavTop = $(".mobile-nav").offset().top - 60;

        // our function that decides weather the navigation bar should have "fixed" css position or not.
        var stickyAnchorNav = function() {
            var scrollTop = $(window).scrollTop(); // our current vertical position from the top

            // if we've scrolled more than the navigation, change its position to fixed to stick to top,
            // otherwise change it back to relative
            if (scrollTop > stickyNavTop) {
                $(".mobile-nav").addClass("sticky");
            } else {
                $(".mobile-nav").removeClass("sticky");
            }
        };

        stickyAnchorNav();
        // and run it again every time you scroll
        $(window).scroll(function() {
            stickyAnchorNav();
        });
    }
    chevronNav.init();
}

export default { init };
