window.dataLayer = window.dataLayer || [];

// To debug you can flip this flag or run the below command in the console
// localStorage.setItem("track_debug", true);
const debug = false;

/* eslint-disable */
// Push to the dataLayer, with a failsafe
export const pushTo = obj => {
    if (debug || localStorage.getItem("track_debug")) {
        console.group(`Track event ${obj.event}`);
        console.table(obj);
        console.groupEnd();
    }

    // Use try to prevent js issues with ad blockers
    try {
        if (window.dataLayer) {
            window.dataLayer.push(obj);
        }
    } catch (error) {
        console.warn(error);
    }
};
/* eslint-enable */

// Push GTM custom events into dataLayer
export const pushEvent = event => pushTo(event);

// Push google analytics events into dataLayer
export const trackEvent = obj => pushTo(obj);

window.addEventListener("track-event", ({ detail }) => pushTo(detail));
window.sui = window.sui || {};
window.sui.trackEvent = detail => pushTo(detail);
