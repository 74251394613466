(function(a) {
    a.fn.niceSelect = function(d) {
        function c(j) {
            j.after(
                a("<div></div>")
                    .addClass("nice-select")
                    .addClass(j.attr("class") || "")
                    .addClass(j.attr("disabled") ? "disabled" : "")
                    .attr("tabindex", j.attr("disabled") ? null : "0")
                    .html('<span class="current"></span><ul class="list"></ul>')
            );
            var h = j.next(),
                g = j.find("option"),
                f = j.find("option:selected");
            h.find(".current").html(f.data("display") || f.text()),
                g.each(function() {
                    var l = a(this),
                        k = l.data("display");
                    h.find("ul").append(
                        a("<li></li>")
                            .attr("data-value", l.val())
                            .attr("data-display", k || null)
                            .addClass("option" + (l.is(":selected") ? " selected" : "") + (l.is(":disabled") ? " disabled" : ""))
                            .html(l.text())
                    );
                });
        }
        if ("string" == typeof d) {
            return (
                "update" == d
                    ? this.each(function() {
                          var h = a(this),
                              g = a(this).next(".nice-select"),
                              f = g.hasClass("open");
                          g.length && (g.remove(), c(h), f && h.next().trigger("click"));
                      })
                    : "destroy" == d
                    ? (this.each(function() {
                          var g = a(this),
                              f = a(this).next(".nice-select");
                          f.length && (f.remove(), g.css("display", ""));
                      }),
                      0 == a(".nice-select").length && a(document).off(".nice_select"))
                    : console.log('Method "' + d + '" does not exist.'), //eslint-disable-line no-console
                this
            );
        }
        this.hide(),
            this.each(function() {
                var f = a(this);
                f.next().hasClass("nice-select") || c(f);
            }),
            a(document).off(".nice_select"),
            a(document).on("click.nice_select", ".nice-select", function() {
                var f = a(this);
                a(".nice-select")
                    .not(f)
                    .removeClass("open"),
                    f.toggleClass("open"),
                    f.hasClass("open") ? (f.find(".option"), f.find(".focus").removeClass("focus"), f.find(".selected").addClass("focus")) : f.focus();
            }),
            a(document).on("click.nice_select", function(f) {
                0 === a(f.target).closest(".nice-select").length &&
                    a(".nice-select")
                        .removeClass("open")
                        .find(".option");
            }),
            a(document).on("click.nice_select", ".nice-select .option:not(.disabled)", function() {
                var h = a(this),
                    g = h.closest(".nice-select");
                g.find(".selected").removeClass("selected"), h.addClass("selected");
                var f = h.data("display") || h.text();
                g.find(".current").text(f),
                    g
                        .prev("select")
                        .val(h.data("value"))
                        .trigger("change");
            }),
            a(document).on("keydown.nice_select", ".nice-select", function(k) {
                var j = a(this),
                    h = a(j.find(".focus") || j.find(".list .option.selected"));
                if (32 == k.keyCode || 13 == k.keyCode) {
                    return j.hasClass("open") ? h.trigger("click") : j.trigger("click"), !1;
                }
                if (40 == k.keyCode) {
                    if (j.hasClass("open")) {
                        var f = h.nextAll(".option:not(.disabled)").first();
                        f.length > 0 && (j.find(".focus").removeClass("focus"), f.addClass("focus"));
                    } else {
                        j.trigger("click");
                    }
                    return !1;
                }
                if (38 == k.keyCode) {
                    if (j.hasClass("open")) {
                        var g = h.prevAll(".option:not(.disabled)").first();
                        g.length > 0 && (j.find(".focus").removeClass("focus"), g.addClass("focus"));
                    } else {
                        j.trigger("click");
                    }
                    return !1;
                }
                if (27 == k.keyCode) {
                    j.hasClass("open") && j.trigger("click");
                } else {
                    if (9 == k.keyCode && j.hasClass("open")) {
                        return !1;
                    }
                }
            });
        var b = document.createElement("a").style;
        return (b.cssText = "pointer-events:auto"), "auto" !== b.pointerEvents && a("html").addClass("no-csspointerevents"), this;
    };
})(jQuery);
