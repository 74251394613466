function init() {
    $(".nav-scroller-btn--right").click(function(e) {
        e.preventDefault();
        $(".nav-scroller-nav").animate(
            {
                scrollLeft: "+=200px"
            },
            "slow"
        );
    });

    $(".nav-scroller-btn--left").click(function(e) {
        e.preventDefault();
        $(".nav-scroller-nav").animate(
            {
                scrollLeft: "-=200px"
            },
            "slow"
        );
    });

    $(".section-secondary-nav .secondary-nav > li > a").on("click", function(e) {
        e.preventDefault();

        $(this)
            .parent()
            .toggleClass("open");
    });

    if ($(".nav-scroller-nav").length > 0) {
        var scrollNav = $(".nav-scroller-nav");
        scrollNav
            .on("scroll", function() {
                var scroll = scrollNav.scrollLeft();

                if (scroll >= 1) {
                    $(".nav-scroller-btn--left")
                        .removeClass("disabled")
                        .addClass("text-green-haze");
                    $(".nav-scroller-nav").removeClass("grad-left-hide");
                } else {
                    $(".nav-scroller-btn--left")
                        .removeClass("text-green-haze")
                        .addClass("disabled");
                    $(".nav-scroller-nav").addClass("grad-left-hide");
                }

                if (scrollNav.outerWidth() + scrollNav.scrollLeft() >= scrollNav[0].scrollWidth) {
                    $(".nav-scroller-btn--right")
                        .removeClass("text-green-haze")
                        .addClass("disabled");
                    $(".nav-scroller-nav").addClass("grad-right-hide");
                } else {
                    $(".nav-scroller-btn--right")
                        .removeClass("disabled")
                        .addClass("text-green-haze");
                    $(".nav-scroller-nav").removeClass("grad-right-hide");
                }
            })
            .trigger("scroll");
        $(window).on("load", secondaryNav);

        $(".hero-title").addClass("no-floating");
    }

    function secondaryNav() {
        var scrollNav = $(".nav-scroller-nav");
        var scrollNavActive = $(".nav-scroller-nav .active");
        scrollNav.scrollLeft(scrollNavActive.position().left + scrollNavActive.outerWidth() / 2 - scrollNav.outerWidth() / 2);
    }
}

export default { init };
