import DOMPurify from "dompurify";
// --------------HELPER FUNCTIONS-------------------------

//Remove Child Nodes
let removeChildNodes = node => {
    while (node.firstChild) {
        node.removeChild(node.firstChild);
    }
};

//select teleport location
const selectTeleportLocation = function() {
    return document.querySelector(".vertical-accordion-container_content");
};

const teleport = (teleportationLocation, template) => {
    const temp = template.content.querySelector("div").cloneNode(true);

    if (teleportationLocation.childNodes.length === 0) {
        teleportationLocation.appendChild(temp);
    } else {
        removeChildNodes(teleportationLocation);
        teleportationLocation.appendChild(temp);
    }
};
//close accordion Sections
const closeSection = () => {
    let $sections = $(".vertical-accordion-section_button");
    let $collapsable = $(".section-wrapper .expansion-block");
    $sections.next().collapse("hide");

    $sections.next().on("show.bs.collapse", event => {
        let $event = $(event.target);
        $sections
            .next()
            .not($event)
            .collapse("hide");
    });

    $sections.on("click", event => {
        let $event = $(event.target);
        event.preventDefault();

        $event.toggleClass("collapsed");
        if ($event.parent().is(":nth-last-child(2)")) {
            $collapsable.css("border-top", "none");
        } else {
            $collapsable.css("border-top", "2px solid #009966");
        }
        $sections.not($event).addClass("collapsed");
        $event.next().collapse("toggle");
    });
};

//show on open
const showOnOpen = () => {
    const url = new URL(DOMPurify.sanitize(document.location));
    let hash = url.hash;
    let accordionItems = document.querySelectorAll(".vertical-accordion-item");
    let accordionItemsArray = Array.from(accordionItems, elem => elem.id);
    let element = document.getElementById(hash);
    let defaultElement = document.querySelector(".vertical-accordion-item");

    const target = element && accordionItemsArray.includes(hash) ? element : defaultElement;
    const targetButton = target.parentNode.previousElementSibling.closest("a");
    const targetSection = targetButton.nextElementSibling;

    targetButton.classList.remove("collapsed");
    $(targetSection).collapse("show");
    target.classList.add("active");
    target.click();

    if (element && accordionItemsArray.includes(hash)) {
        window.addEventListener("load", () => {
            document.querySelector(".vertical-accordion-container").scrollIntoView({
                behavior: "smooth"
            });
        });
    }
};

// --------------MAIN FUNCTION-------------------------

const verticalAccordion = () => {
    const verticalAccordionContainer = document.querySelector(".vertical-accordion-container");
    //check if component is there
    if (!verticalAccordionContainer) {
        return;
    }

    closeSection();
    //grab all accordion items
    let accordionItems = verticalAccordionContainer.querySelectorAll(".vertical-accordion-item");
    //grabbing accordionItem Data
    accordionItems.forEach(accordionItem => {
        accordionItem.addEventListener("click", e => {
            e.preventDefault();
            //add active class AND remove previous active classes
            e.target.classList.add("active");
            accordionItems.forEach(item => {
                if (item !== e.target && item.classList.contains("active")) {
                    item.classList.remove("active");
                }
            });
            //grab template
            let template = e.currentTarget.nextElementSibling;
            //get teleportation location
            let teleportationLocation = selectTeleportLocation(e);
            //telport it!
            teleport(teleportationLocation, template);
        });
    });
    showOnOpen();
    //scroll container into view
};

export default verticalAccordion;
