function init() {
    function createCookie(name, value) {
        var expires = "";
        document.cookie = name + "=" + value + "; " + expires + "; path=/";
    }

    function readCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(";");

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    var banner = $("#banner-notice"),
        close = banner.find(".banner-close"),
        bannerCookieName = "COVID-19-close";
    // If cookie not set, show the COVID-19 banner
    if (!readCookie(bannerCookieName) && banner.length && $(window).width() > 767) {
        $("body").addClass("show-banner-bar");
    }
    // Close button
    close.on("click", function(e) {
        e.preventDefault();
        // Set a cookie to expire for this browser session
        createCookie(bannerCookieName, "true");
        $("body").removeClass("show-banner-bar");
    });
}

export default { init };
