/* ========================================================================
 * Bootstrap: dropdown.js v3.3.7
 * http://getbootstrap.com/javascript/#dropdowns
 * ========================================================================
 * Copyright 2011-2016 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 * ======================================================================== */

+(function($) {
    "use strict";

    // DROPDOWN CLASS DEFINITION
    // =========================

    var backdrop = ".dropdown-backdrop";
    var toggle = '[data-toggle="dropdown"]';
    var Dropdown = function(element) {
        $(element).on("click.bs.dropdown", this.toggle);
    };

    Dropdown.VERSION = "3.3.7";

    function getParent($this) {
        var selector = $this.attr("data-target");

        if (!selector) {
            selector = $this.attr("href");
            selector = selector && /#[A-Za-z]/.test(selector) && selector.replace(/.*(?=#[^\s]*$)/, ""); // strip for ie7
        }

        var $parent = selector && $(selector);

        return $parent && $parent.length ? $parent : $this.parent();
    }

    function clearMenus(e) {
        if (e && e.which === 3) return;
        $(backdrop).remove();
        $(toggle).each(function() {
            var $this = $(this);
            var $parent = getParent($this);
            var relatedTarget = { relatedTarget: this };

            if (!$parent.hasClass("open")) return;

            if (e && e.type == "click" && /input|textarea/i.test(e.target.tagName) && $.contains($parent[0], e.target)) return;

            $parent.trigger((e = $.Event("hide.bs.dropdown", relatedTarget)));

            if (e.isDefaultPrevented()) return;

            $this.attr("aria-expanded", "false");
            $parent.removeClass("open").trigger($.Event("hidden.bs.dropdown", relatedTarget));
        });
    }

    Dropdown.prototype.toggle = function(e) {
        var $this = $(this);

        if ($this.is(".disabled, :disabled")) return;

        var $parent = getParent($this);
        var isActive = $parent.hasClass("open");

        clearMenus();

        if (!isActive) {
            if ("ontouchstart" in document.documentElement && !$parent.closest(".navbar-nav").length) {
                // if mobile we use a backdrop because click events don't delegate
                $(document.createElement("div"))
                    .addClass("dropdown-backdrop")
                    .insertAfter($(this))
                    .on("click", clearMenus);
            }

            var relatedTarget = { relatedTarget: this };
            $parent.trigger((e = $.Event("show.bs.dropdown", relatedTarget)));

            if (e.isDefaultPrevented()) return;

            $this.trigger("focus").attr("aria-expanded", "true");

            $parent.toggleClass("open").trigger($.Event("shown.bs.dropdown", relatedTarget));
        }

        return false;
    };

    Dropdown.prototype.keydown = function(e) {
        if (!/(38|40|27|32)/.test(e.which) || /input|textarea/i.test(e.target.tagName)) return;

        var $this = $(this);

        e.preventDefault();
        e.stopPropagation();

        if ($this.is(".disabled, :disabled")) return;

        var $parent = getParent($this);
        var isActive = $parent.hasClass("open");

        if ((!isActive && e.which != 27) || (isActive && e.which == 27)) {
            if (e.which == 27) $parent.find(toggle).trigger("focus");
            return $this.trigger("click");
        }

        var desc = " li:not(.disabled):visible a";
        var $items = $parent.find(".dropdown-menu" + desc);

        if (!$items.length) return;

        var index = $items.index(e.target);

        if (e.which == 38 && index > 0) index--; // up
        if (e.which == 40 && index < $items.length - 1) index++; // down
        if (!~index) index = 0;

        $items.eq(index).trigger("focus");
    };

    // DROPDOWN PLUGIN DEFINITION
    // ==========================

    function Plugin(option) {
        return this.each(function() {
            var $this = $(this);
            var data = $this.data("bs.dropdown");

            if (!data) $this.data("bs.dropdown", (data = new Dropdown(this)));
            if (typeof option == "string") data[option].call($this);
        });
    }

    var old = $.fn.dropdown;

    $.fn.dropdown = Plugin;
    $.fn.dropdown.Constructor = Dropdown;

    // DROPDOWN NO CONFLICT
    // ====================

    $.fn.dropdown.noConflict = function() {
        $.fn.dropdown = old;
        return this;
    };

    // APPLY TO STANDARD DROPDOWN ELEMENTS
    // ===================================

    $(document)
        //.on('click.bs.dropdown.data-api', clearMenus)
        .on("click.bs.dropdown.data-api", ".dropdown form", function(e) {
            e.stopPropagation();
        })
        .on("click.bs.dropdown.data-api", toggle, Dropdown.prototype.toggle)
        .on("keydown.bs.dropdown.data-api", toggle, Dropdown.prototype.keydown)
        .on("keydown.bs.dropdown.data-api", ".dropdown-menu", Dropdown.prototype.keydown);
})(jQuery);

function init() {
    var $body = $("body"),
        currentLocation = location.pathname.length > 1 ? stripTrailingSlash(location.pathname) : location.pathname;

    //fix for score nav menu toggle
    $(".navbar-toggle").addClass("collapsed");

    //add active state to nav
    $('.nav a[href="' + currentLocation + '"]')
        .parents("li")
        .each(function() {
            $(this).addClass("active");
        });

    //open active subnav
    $(".score-nav .score-menu-link-item.active")
        .parents(".dropdown, .score-sublinks")
        .addClass("open");

    $(".score-nav > .navbar-collapse")
        .on("hidden.bs.collapse", function() {
            $body.removeClass("nav-open");

            $(".score-nav .dropdown").removeClass("open");
            $(".score-nav .score-menu-link-item.active")
                .parents(".dropdown, .score-sublinks")
                .addClass("open");
        })
        .on("show.bs.collapse", function() {
            $body.addClass("nav-open");
        });

    //fix iPad hover dropdown
    $("li.dropdown").on("touchstart", function(e) {
        if ($(window).width() > 991) {
            e.stopPropagation();
            if (!$(this).hasClass("hover")) {
                e.preventDefault();
                $("li.dropdown.hover").removeClass("hover");
                $(this).addClass("hover");
            }
        }
    });

    //close dropdowns when tapping outside them
    $body.on("touchstart", function() {
        $("li.dropdown.hover").removeClass("hover");
        $(".pi-dropdown").removeClass("open");
    });

    //prevent propagation when tapping isde PI dropdown
    $(".pi-dropdown").on("touchstart", function(e) {
        e.stopPropagation();
    });

    //toggle dropdowns based on A tag clicks
    $(".navbar-default.score-megamenu .navbar-nav > li.dropdown > a:not(.toggle-caret)").on("click", function(e) {
        e.preventDefault();

        $(this)
            .parent()
            .toggleClass("open")
            .siblings(".toggle-caret")
            .dropdown("toggle");
    });

    $(".score-megamenu-content .text-component").on("click", function() {
        $(this)
            .parent()
            .toggleClass("open");
        $(this)
            .parent()
            .siblings()
            .removeClass("open");
        $(".score-nav .dropdown.open").removeClass("open");
    });

    $(".score-nav .dropdown").on("show.bs.dropdown", function() {
        $(".score-column3-equal > .open").removeClass("open");
    });
}

function stripTrailingSlash(str) {
    if (str.substr(-1) === "/") {
        return str.substr(0, str.length - 1);
    }

    return str;
}
export default { init };
