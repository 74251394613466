const profileTabs = () => {
    return {
        tabs: [],
        currentIndex: -1,
        init() {
            this.tabs = document.querySelectorAll(".profileTabs__tabs a");
            this.tabs.forEach((item, index) => {
                item.tabIndex = 0; // Set tabIndex for each tab

                item.addEventListener("focus", () => {
                    this.currentIndex = index;
                });
            });
        },
        nextTabFocus() {
            this.currentIndex = (this.currentIndex + 1) % this.tabs.length;
            this.tabs[this.currentIndex].focus();
        },
        prevTabFocus() {
            this.currentIndex = (this.currentIndex - 1 + this.tabs.length) % this.tabs.length;
            this.tabs[this.currentIndex].focus();
        }
    };
};

export default profileTabs;
