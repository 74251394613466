import AOS from "aos";

function aosInit() {
    // aos config
    var aosElements = document.querySelectorAll(".aos-fade-up, .aos-item, .aos-delay");

    for (let i = 0; i < aosElements.length; i++) {
        aosElements[i].setAttribute("data-aos", "fade-up");
    }
    if (window.location.hash.length && !document.querySelector("#webinar-video-modal")) {
        $("body").addClass("disable-aos");
    }

    AOS.init({
        offset: 150,
        duration: 800,
        easing: "ease-out-quad",
        once: true
    });

    const anchorTabs = function(event) {
        if (event.target.classList.contains("ui-tabs-anchor") || event.target.classList.contains("collapse-toggle") || window.device === "mobile") {
            AOS.refresh();
        }
    };

    window.onclick = anchorTabs;

    window.onload = function() {
        setTimeout(function() {
            AOS.refresh();
        }, 2500);
    };
}
export default aosInit;
