const contentTab = document.querySelectorAll(".gallery-wrapper li.videos-tab");

const init = () => {
    contentTab.forEach(tab => {
        let tabContentId = tab.querySelector("a").getAttribute("href");
        let tabContentElement = document.querySelector(tabContentId);
        let tabContentParent = tabContentElement.closest(".tab-content");
        tab.addEventListener("click", () => {
            tabContentParent.scrollTop = 0;
        });
    });
};
export default { init };
