function loadCarousel() {
    let prevButton = document.querySelector("#hero-carousel-desktop .fa-chevron-left");
    let prevButtonMobile = document.querySelector("#hero-carousel-mobile .fa-chevron-left");

    let nextButton = document.querySelector("#hero-carousel-desktop .fa-chevron-right");
    let nextButtonMobile = document.querySelector("#hero-carousel-mobile .fa-chevron-right");

    let pausePlayButton = document.querySelector(".pause-play");
    let pausePlayButtonMobile = document.querySelector("#hero-carousel-mobile .pause-play");

    let images = document.querySelectorAll(".home-carousel .hero-carousel-desktop .carousel-inner .item");
    let imagesMobile = document.querySelectorAll(".home-carousel .hero-carousel-mobile .carousel-inner .item");

    let carouselDots = document.querySelectorAll(".home-carousel .hero-carousel-desktop .carousel-indicators-container .carousel-indicators > li");
    let carouselDotsMobile = document.querySelectorAll(".home-carousel .hero-carousel-mobile .carousel-indicators-container .carousel-indicators > li");

    let active = 0;
    let carouselInterval;
    let slideDuration = 6000;

    function nextSlide() {
        clearInterval(carouselInterval);
        carouselDots[active].classList.remove("active");
        carouselDotsMobile[active].classList.remove("active");
        removeActive(active);
        if (active < images.length - 1) {
            // Current Slide
            applyFadeDownRemoveFadeUp(active);

            // Next Slide
            removeFadeDown(++active);
        } else {
            // First Slide
            active = 0;
            applyFadeUpRemoveFadeDown(active);
        }
        carouselDots[active].classList.add("active");
        carouselDotsMobile[active].classList.add("active");
        applyActive(active);

        if (pausePlayButton.dataset.status == "play") {
            carouselInterval = setInterval(() => {
                nextSlide();
            }, slideDuration);
        }
    }

    function prevSlide() {
        clearInterval(carouselInterval);
        carouselDots[active].classList.remove("active");
        carouselDotsMobile[active].classList.remove("active");
        removeActive(active);
        if (active <= 0) {
            for (let i = 0; i < images.length - 1; i++) {
                applyFadeDownRemoveFadeUp(i);
            }

            active = images.length - 1;
        } else {
            // Previous Slide
            applyFadeUpRemoveFadeDown(--active);
        }

        carouselDots[active].classList.add("active");
        carouselDotsMobile[active].classList.add("active");

        applyActive(active);

        if (pausePlayButton.dataset.status == "play") {
            carouselInterval = setInterval(() => {
                nextSlide();
            }, slideDuration);
        }
    }

    function pausePlay() {
        let status = pausePlayButton.dataset.status;
        clearInterval(carouselInterval);
        if (status == "pause") {
            togglePlay();
            carouselInterval = setInterval(() => {
                nextSlide();
            }, slideDuration);
        } else {
            togglePlay();
        }
    }

    function togglePlay() {
        if (pausePlayButton.classList.contains("fa-pause")) {
            pausePlayButton.dataset.status = "pause";
            pausePlayButtonMobile.dataset.status = "pause";

            pausePlayButton.classList.remove("fa-pause");
            pausePlayButtonMobile.classList.remove("fa-pause");

            pausePlayButton.classList.add("fa-play");
            pausePlayButtonMobile.classList.add("fa-play");
        } else {
            pausePlayButton.dataset.status = "play";
            pausePlayButtonMobile.dataset.status = "play";

            pausePlayButton.classList.add("fa-pause");
            pausePlayButtonMobile.classList.add("fa-pause");

            pausePlayButton.classList.remove("fa-play");
            pausePlayButtonMobile.classList.remove("fa-play");
        }
    }

    function carouselDotSlide(carouselDot) {
        clearInterval(carouselInterval);
        let index = parseInt(carouselDot.dataset.index);
        removeActive(active);
        removeFadeDown(index);
        if (active < index) {
            for (let i = active; i < index; i++) {
                applyFadeDownRemoveFadeUp(i);
            }
        } else if (active > index) {
            applyFadeUp(index);
        }

        carouselDots[active].classList.remove("active");
        carouselDotsMobile[active].classList.remove("active");

        active = index;

        carouselDots[active].classList.add("active");
        carouselDotsMobile[active].classList.add("active");

        applyActive(active);

        if (pausePlayButton.dataset.status == "play") {
            carouselInterval = setInterval(() => {
                nextSlide();
            }, slideDuration);
        }
    }

    function loadFade() {
        nextButton.addEventListener("click", () => {
            nextSlide();
        });

        nextButtonMobile.addEventListener("click", () => {
            nextSlide();
        });

        prevButton.addEventListener("click", () => {
            prevSlide();
        });

        prevButtonMobile.addEventListener("click", () => {
            prevSlide();
        });

        pausePlayButton.addEventListener("click", () => {
            pausePlay();
        });

        pausePlayButtonMobile.addEventListener("click", () => {
            pausePlay();
        });

        for (let carouselDot of carouselDots) {
            carouselDot.addEventListener("click", () => {
                carouselDotSlide(carouselDot);
            });
        }

        for (let carouselDot of carouselDotsMobile) {
            carouselDot.addEventListener("click", () => {
                carouselDotSlide(carouselDot);
            });
        }

        carouselInterval = setInterval(() => {
            nextSlide();
        }, slideDuration);
    }

    function applyFadeUp(index) {
        images[index].classList.add("fade-up");
        imagesMobile[index].classList.add("fade-up");
    }

    function removeFadeDown(index) {
        images[index].classList.remove("fade-down");
        imagesMobile[index].classList.remove("fade-down");
    }

    function applyFadeDownRemoveFadeUp(index) {
        images[index].classList.add("fade-down");
        images[index].classList.remove("fade-up");

        imagesMobile[index].classList.add("fade-down");
        imagesMobile[index].classList.remove("fade-up");
    }

    function applyFadeUpRemoveFadeDown(index) {
        images[index].classList.add("fade-up");
        images[index].classList.remove("fade-down");

        imagesMobile[index].classList.add("fade-up");
        imagesMobile[index].classList.remove("fade-down");
    }

    function removeActive(index) {
        images[index].classList.remove("active");
        imagesMobile[index].classList.remove("active");
    }

    function applyActive(index) {
        images[index].classList.add("active");
        imagesMobile[index].classList.add("active");
    }

    if (document.querySelector(".home-carousel")) loadFade();
}

export default { loadCarousel };
