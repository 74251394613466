import { qValidation } from "../validations";
function buttonEnableDisable() {
    if (qValidation()) {
        $("#btnDynamicFormSubmit").prop("disabled", false);
    } else {
        // $("#btnDynamicFormSubmit").prop("disabled", true); <== uncomment when reCaptcha is functional
        $("#btnDynamicFormSubmit").prop("disabled", false); //bypass reCaptcha to enable submit button
    }
}

export default buttonEnableDisable;
