export default function webGalleryScroll(elem) {
    // eslint-disable-next-line no-undef
    if (videojs) {
        if (document.location.hash) {
            elem.scrollIntoView({
                behavior: "smooth"
            });
        }
    }
}
