/* -------------------
BEGIN core-interstitials 
Sitecore Resource: fc0f8c10-a5ec-498e-9e24-bb8bd6ae82cc
function init() {
-------------------*/
function init() {
    function Interstitials() {
        this.links = this.getURLS("#hdnHCPLinks, #hdnExternalLinks, #hdnRegionLinks, #hdnUnbrandedLinks");

        $(".websiteBtn").on("click", function() {
            $(this)
                .parents(".modal")
                .modal("hide");
            window.open($(this).data("url"));
        });

        $("body").on("show.bs.modal", function(e) {
            var url = $(e.relatedTarget).attr("href"),
                modal = $(e.relatedTarget).data("target");

            $(modal)
                .find(".websiteBtn")
                .data("url", url);
        });

        this.scanLinks();
    }

    Interstitials.prototype.scanLinks = function() {
        for (var key in this.links) {
            let modalID = key,
                urls = this.links[key];

            for (var index = 0; index < urls.length; index++) {
                $('a[href*="' + urls[index] + '"]').attr({
                    "data-toggle": "modal",
                    "data-remote": false,
                    "data-target": "#" + modalID
                });
            }
        }
    };

    Interstitials.prototype.getURLS = function(selector) {
        let regex = /http:\/\/|https:\/\/|www.| /g,
            urls = {},
            modalID;

        $(selector).each(function() {
            let arr = $(this)
                .val()
                .toLowerCase() //lowercase
                .replace(regex, "") //remove protocol and www subdomin from urls
                .split(","); //split in to array

            switch (
                $(this).attr("id") //set appropriate target modal id
            ) {
                case "hdnHCPLinks":
                    modalID = "hcpMod";
                    break;
                case "hdnExternalLinks":
                    modalID = "thirdPartyMod";
                    break;
                case "hdnRegionLinks":
                    modalID = "regionMod";
                    break;
                case "hdnUnbrandedLinks":
                    modalID = "unbrandedMod";
                    break;
            }

            urls[modalID] = arr;
        });

        return urls; //object with key and value pair as modal id and urls
    };

    // eslint-disable-next-line no-unused-vars
    let interstitials;

    $(function() {
        interstitials = new Interstitials();
    });
}

export default { init };
