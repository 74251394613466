//create cookie
function createCookie(name, value) {
    var expires = "";
    document.cookie = name + "=" + value + "; " + expires + "; path=/";
}
//read cookie
function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(";");

    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

//stop animated scroll on user scroll
function handleUserScroll() {
    $("html, body").stop(); //stop scroll animation if user scrolls
}

function scrollToEl(el) {
    var page = $("html, body"),
        body = $("body");

    if (typeof el === "string") el = $(el);

    //listen for user scroll
    page.on("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", handleUserScroll);

    return page.animate(
        {
            scrollTop: el.offset().top
        },
        {
            progress: function() {
                body.removeClass("header-sticky").addClass("header-hide");
            },
            complete: function() {
                body.removeClass("header-sticky").addClass("header-hide");
                page.off("scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove", handleUserScroll); //remove scroll listener
            }
        }
    );
}

function init() {
    var isiReadMoreText = typeof readmore === "undefined" ? '<span class="hidden-xs">Read </span>More</strong>' : "";

    $(".isi")
        .clone()
        .addClass("sticky invisible")
        .insertAfter(".isi")
        .on("click", function(e) {
            e.preventDefault();
            scrollToEl(".isi:not(.sticky)");
        })
        .find(".container")
        .append(`<a class="read-more spanish-no-display" href="#"><strong>${isiReadMoreText}</strong> <i class="fa fa-chevron-circle-down"></i></a>`);

    $(window)
        .on("scroll resize", function() {
            var stickyOffset = $(".isi.sticky").position().top,
                scrollDest = $(".isi").offset().top - $(document).scrollTop();

            stickyOffset <= scrollDest ? $(".isi.sticky").removeClass("invisible") : $(".isi.sticky").addClass("invisible");
        })
        .trigger("resize");

    /*$('input[type="text"], input[type="number"], input[type="email"], input[type="password"], input[type="date"], input[type="tel"], input[type="datetime"], input[type="month"], input[type="radio"], input[type="checkbox"]')
    .on('focus', function(e){
        if($(window).width() < 992)$('.isi.sticky').addClass('hidden');
    }).on('blur', function(e){
        if($(window).width() < 992)$('.isi.sticky').removeClass('hidden');
    });*/

    // Add ID to ISI
    $(".score-footer")
        .find(".isi")
        .first()
        .attr("id", "isi");

    var indication = readCookie("indication");

    if (readCookie(`${indication}-isi-collapsed`)) $("html").addClass("collapsed-sticky-isi");

    $(".isi")
        .off("click")
        .find(".read-more")
        .remove();

    $(".isi.sticky")
        .find(".container")
        .prepend('<span class="sticky-isi-toggles"><button class="expand-isi" aria-label="expand important safety information"><i class="fa fa-plus-circle"></i></button><span class="sep"></span><button class="collapse-isi" aria-label="collapse important safety information"><i class="fa fa-minus-circle"></i></button></span>');

    $(".expand-isi").on("click", function(e) {
        e.preventDefault();

        //fake scroll position
        //$('body').css('top', -$(window).scrollTop());

        //remove collapsed class and add expanded
        $("html")
            .removeClass("collapsed-sticky-isi")
            .addClass("expanded-sticky-isi");

        //scroll sticky ISI content back to the top on open.
        $(".isi.sticky .score-page").scrollTop(0);

        setExpandedStickyHeight();

        //set cookie that ISI has been interacted
        createCookie(`${indication}-isi-collapsed`, true);
    });

    $(".collapse-isi").on("click", function(e) {
        e.preventDefault();

        //remove expanded class and add collapsed
        $("html")
            .removeClass("expanded-sticky-isi")
            .addClass("collapsed-sticky-isi");

        //reset scroll position
        //let scrollPos = parseInt($('body').css('top')) * -1;
        //$('body').css('top', '');
        //$(window).scrollTop(scrollPos);

        //reset sticky ISI height
        $(".isi.sticky").css({
            height: function() {
                return $(this).outerHeight();
            },
            top: ""
        });

        setTimeout(function() {
            $(".isi.sticky").css("height", "");
        }, 100);

        //set cookie that ISI has been interacted
        createCookie(`${indication}-isi-collapsed`, true);
    });
    $(window)
        .on("scroll resize", function() {
            var stickyOffset = $(".isi.sticky").position().top,
                scrollDest = $(".isi").offset().top - $(document).scrollTop();

            if (stickyOffset > scrollDest) {
                $("html").addClass("collapsed-sticky-isi");
                createCookie(`${indication}-isi-collapsed`, true);
            }
        })
        .trigger("resize");

    function setExpandedStickyHeight() {
        //calculate the headers bottom edge distance from the top of the viewport
        let headerBottomOffsetTop = $("header").offset().top + $("header").outerHeight() - window.scrollY - 1;

        //if that value is negative, set to 0
        if (headerBottomOffsetTop < 0) headerBottomOffsetTop = -1;

        //set height of ISI so the top edge meets the header
        //$('.isi.sticky').css('height', window.innerHeight - headerBottomOffsetTop);
        $(".isi.sticky")
            .stop(true, false)
            .animate(
                {
                    top: headerBottomOffsetTop
                },
                200
            )
            .css({ height: "auto" });
        //$('.isi.sticky').css('top', `calc(100% - ${window.innerHeight - headerBottomOffsetTop}px`);
    }
}

export default { init };
