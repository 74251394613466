import findSpecialtyTeam from "./findSpecialtyTeam";
import showSuccessMessage from "./showSuccessMessage";

function postData(token, recaptcha, params) {
    var dataRequest = $.param(params);
    dataRequest = dataRequest + "&rep=" + findSpecialtyTeam();
    if (recaptcha) {
        dataRequest = dataRequest + "&g-recaptcha-response=" + token;
    }
    var contextPath = $("#contextPath").val();
    $.ajax({
        url: contextPath + "/.rest/contact-rep/",
        dataType: "html",
        type: "post",
        contentType: "application/x-www-form-urlencoded",
        async: false,
        data: dataRequest
    })
        .done(function() {
            showSuccessMessage();
        })
        .fail(function(j) {
            console.log("SendFormData fails"); //eslint-disable-line no-console
            console.log(j); //eslint-disable-line no-console
        })
        .always(function() {});
}

export default postData;
