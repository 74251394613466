const mobileHeader = document.querySelector("#mobile-header");
function init() {
    checkScreenWidth();
    if (mobileHeader) {
        window.addEventListener("scroll", onScrollEvent);
    }
}

$(".explore-button .btn-anchor").on("click", function() {
    let mobileNav = document.querySelector(".mobile-nav");
    let button = document.querySelector(".explore-button .btn-link");
    mobileNav.classList.toggle("in");
    button.classList.toggle("collapsed");
    $("#anchornav-sm-max").collapse("hide");
});

function onScrollEvent() {
    let listArray = document.querySelectorAll("#anchornav-sm-max .nav li");
    listArray.forEach(list => {
        if (list.classList.contains("active")) {
            mobileHeader.innerText = list.innerText.trim();
            return;
        }
    });
}

function checkScreenWidth() {
    if ($(window).width() > 991) {
        //Scroll spy to select active anchors
        $("body").scrollspy({ target: "#anchornav", offset: 300 });
    } else if ($(window).width() < 991) {
        //Scroll spy to select active anchors
        $("body").scrollspy({ target: "#anchornav-sm-max", offset: 165 });
    }
}
export default { init };
