function _checkCurrentBreakpoint(currentWidth, listBreakpoint) {
    let current = null;
    const bp = [...listBreakpoint[0].breakpoint];
    current = bp[bp.length - 1];
    if (bp.length) {
        for (let i of bp) {
            if (currentWidth <= i) {
                current = i;
                break;
            }
        }
    }
    return current;
}
function _getViewportWidth() {
    return window.innerWidth || document.documentElement.clientWidth;
}

const responsive = [{ breakpoint: [768, 991, 1200, 1400] }];
let currentBreakPoint = _checkCurrentBreakpoint(_getViewportWidth(), responsive);

class TableCustom {
    constructor() {
        this.tableWithDrawers = document.querySelectorAll(".table-with-drawers");
        if (!this.tableWithDrawers) return;
        this.init();
    }

    setSameHeightColumn() {
        const $this = this;
        $this.tableWithDrawers.forEach(node => {
            const columns = node.querySelectorAll(".column");
            if (columns.length && columns[0].querySelectorAll(".cell").length) {
                columns[0].querySelectorAll(".cell").forEach((el, index) => {
                    let max = 0;
                    columns.forEach(node => {
                        const cell = node.querySelectorAll(".cell")[index];

                        if (cell) {
                            cell.style.height = null;
                            let cellHeight = cell.clientHeight;

                            if (cellHeight > max) max = cellHeight;
                        }
                    });

                    columns.forEach(node => {
                        const cell = node.querySelectorAll(".cell")[index];
                        if (cell) {
                            cell.style.height = `${max}px`;
                        }
                    });
                });
            }
        });
    }

    resetHeightColumn() {
        const $this = this;
        $this.tableWithDrawers.forEach(node => {
            node.querySelectorAll(".cell").forEach(cell => {
                cell.style.height = "";
            });
        });
    }

    resetCollapse() {
        const $this = this;
        $this.tableWithDrawers.forEach(node => {
            node.querySelectorAll(".collapse").forEach(collapse => {
                collapse.classList.add("in");
            });
        });
    }

    returnCurrentBreakPoint() {
        const $this = this;
        let currentWidth = _getViewportWidth();
        let newBreakpoint = _checkCurrentBreakpoint(currentWidth, responsive);
        if (newBreakpoint !== null && newBreakpoint !== currentBreakPoint) {
            if (window.matchMedia("(min-width: 768px)").matches) {
                $this.resetCollapse();
                $this.resetHeightColumn();
                $this.setSameHeightColumn();
            }
            $this.handleCollapseOnMobile();
        }
        if (window.matchMedia("(min-width: 768px)").matches && window.matchMedia("(max-width: 992px)").matches) {
            $this.resetHeightColumn();
            $this.setSameHeightColumn();
        }
        currentBreakPoint = newBreakpoint;
    }

    handleCollapseOnMobile() {
        const $this = this;
        if (window.matchMedia("(max-width: 991px)").matches) {
            $this.tableWithDrawers.forEach(node => {
                node.querySelectorAll(".collapse-toggle").forEach((collapse, index) => {
                    if (index > 0) collapse.click();
                });
            });
        }
    }

    handleResize() {
        const $this = this;
        window.addEventListener("resize", () => {
            $this.returnCurrentBreakPoint();
        });
    }

    init() {
        const $this = this;
        $this.setSameHeightColumn();
        $this.handleResize();
        $this.handleCollapseOnMobile();
    }
}

export default TableCustom;
