function init() {
    const emailDocModal = $("#email-doc-modal");
    var inputEmail = emailDocModal.find("input[name='email']");
    var regexEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    inputEmail.on("keyup", function() {
        if (inputEmail.val().match(regexEmail)) {
            emailDocModal.find(".errorLb").hide();
            inputEmail.removeClass("hasError");
        }
    });

    emailDocModal.on("show.bs.modal", function() {
        emailDocModal.find(".form-content").removeClass("hidden");
        emailDocModal.find(".form-success").addClass("hidden");
        inputEmail.val("").removeClass("hasError");
        emailDocModal.find(".errorLb").hide();
    });

    emailDocModal.find("form").on("submit", function(e) {
        e.preventDefault();
        const el = $(this);
        const urlEmail = el.attr("action");
        const emailField = el.find("input[name='email']");
        var v2Override = el.find("input[name='useV2']").val();

        if (emailField && emailField.val().match(regexEmail)) {
            emailDocModal.find(".errorLb").hide();
            inputEmail.removeClass("hasError");
            const finalURL = el.find("input[name='document']").val();
            const title = el.find("input[name='title']").val();
            const jobCode = el.find("input[name='jobCode']").val();
            const lastUpdate = el.find("input[name='lastUpdate']").val();
            const emailAddress = emailField.val();

            const formData = new URLSearchParams();
            formData.append("email", emailAddress);
            if (v2Override === "true") {
                formData.append("displayName", title);
                formData.append("displayUrl", finalURL.replace(/(^\w+:|^)\/\//, ""));
            } else {
                formData.append("title", title);
                formData.append("document", finalURL);
                formData.append("jobCode", jobCode);
                formData.append("lastUpdate", lastUpdate);
                formData.append("template", "doc-mailer-hcp");
            }

            $.ajax({
                type: "POST",
                url: urlEmail,
                data: formData.toString(),
                contentType: "application/x-www-form-urlencoded",
                async: false,
                success: function() {
                    emailDocModal.find(".form-content").addClass("hidden");
                    emailDocModal.find(".form-success").removeClass("hidden");
                },
                error: function(msg) {
                    alert("Error sending email:" + msg);
                    return false;
                }
            });
        } else {
            emailDocModal.find(".errorLb").show();
            emailField.addClass("hasError");
        }
    });
}

export default { init };
