import readCookie from "../utils/readCookie";
import { indications } from "../utils/indications";

function init() {
    let downloadPanels = document.querySelectorAll(".download-panels > div");
    let downloadButtons = document.querySelectorAll(".download-buttons .sub-section");
    let dupixentTray = document.querySelector(".access-dupixent-tray");

    let accessButton = document.querySelector(".accessToggle");
    if (accessButton) {
        accessButton.addEventListener("click", e => {
            e.preventDefault();
            dupixentTray.classList.toggle("js-active");
        });
    }

    let indicationSelector = document.querySelectorAll(".indication-selector");
    indicationSelector.forEach(indication => {
        indication.addEventListener("click", () => {
            dupixentTray.classList.toggle("js-active");
            if (!dupixentTray.classList.contains("is-engaged")) {
                dupixentTray.classList.add("is-engaged");
            }
            document.querySelector(".accessToggle .button-name").innerText = indication.innerText;
            downloadPanelsDisplay(indication.dataset.section, downloadPanels);
            //click the first tab of the visible tabs
            $(".section-selector:visible")[0].click();
        });
    });
    //indication selector
    let sectionSelector = document.querySelectorAll(".section-selector");
    sectionSelector.forEach(section => {
        section.addEventListener("click", () => {
            sectionSelector.forEach(section => {
                if (section.classList.contains("active")) {
                    section.classList.remove("active");
                }
            });
            section.classList.add("active");
            downloadButtonsDisplay(section.id, downloadButtons);
        });
    });
    indicationCheck(downloadPanels);
}

function downloadPanelsDisplay(index, downloadPanels) {
    downloadPanels.forEach(panel => {
        if (panel.id != index) {
            panel.classList.add("hidden");
        } else {
            panel.classList.remove("hidden");
        }
    });
}

function downloadButtonsDisplay(id, downloadButtons) {
    downloadButtons.forEach(panel => {
        if (panel.dataset.section !== id) {
            panel.classList.add("hidden");
        } else {
            panel.classList.remove("hidden");
        }
    });
}
function indicationCheck(downloadPanels) {
    let indication = readCookie("indication");
    switch (indication) {
        case indications[1]: //asthma
            attributeChange("Moderate-to-Severe Asthma ", 0, downloadPanels);
            break;
        case indications[0]: //atopic
            attributeChange("Moderate-to-Severe Atopic Dermatitis ", 1, downloadPanels);
            break;
        case indications[6]: //COPD
            attributeChange("Chronic Obstructive Pulmonary Disease", 2, downloadPanels);
            break;
        case indications[2]: //NP
            attributeChange("Chronic Rhinosinusitis with Nasal Polyposis", 3, downloadPanels);
            break;
        case indications[3]: //EOE
            attributeChange("Eosinophilic Esophagitis", 4, downloadPanels);
            break;
        case indications[4]: //PN
            attributeChange("Prurigo Nodularis", 5, downloadPanels);
            break;
        default:
            break;
    }
}
function attributeChange(title, button, downloadPanels) {
    let accessTray = document.querySelector(".access-dupixent-tray");
    if (accessTray) {
        var buttonName = accessTray.querySelector(".button-name");
        if (buttonName) {
            buttonName.innerText = title;
        }
        accessTray.classList.add("is-engaged");
        downloadPanelsDisplay(button, downloadPanels);
    }
}

export default { init };
