import { indications } from "../utils/indications";

const atopicdermatitisList = ["ENT", "Gastroenterologist", "Respiratory Therapist", "Pulmonary Rehabilitation Therapist", "Pulmonologist"];
const asthmaList = ["Dermatologist", "Gastroenterologist"];
const nplist = ["Dermatologist", "Gastroenterologist"];
const eoeList = ["Dermatologist", "ENT", "Respiratory Therapist", "Pulmonary Rehabilitation Therapist", "Pulmonologist"];
const pnList = ["ENT", "Gastroenterologist", "Respiratory Therapist", "Pulmonary Rehabilitation Therapist", "Pulmonologist"];
const csuList = ["ENT", "Gastroenterologist", "Respiratory Therapist", "Pulmonary Rehabilitation Therapist", "Pulmonologist"];
const copdList = ["Allergist", "Dermatologist", "ENT", "Gastroenterologist", "Pediatrician"];

function init() {
    let checkbox = document.querySelectorAll(".treatment .chkLabel .validateCb");
    checkbox.forEach(box => {
        box.addEventListener("click", () => {
            let isChecked = onlyOne(box); // this is used to check if a box is checked on
            if (isChecked) {
                switch (box.getAttribute("id")) {
                    case indications[0]: //atopic
                        specialtyHide(atopicdermatitisList);
                        break;
                    case indications[1]: //asthma
                        specialtyHide(asthmaList);
                        break;
                    case indications[2]: //NP
                        specialtyHide(nplist);
                        break;
                    case indications[3]: //eoe
                        specialtyHide(eoeList);
                        break;
                    case indications[4]: //PN
                        specialtyHide(pnList);
                        break;
                    case indications[5]: //CSU
                        specialtyHide(csuList);
                        break;
                    case indications[6]: //COPD
                        specialtyHide(copdList);
                        break;
                    default:
                        break;
                }
            }
        });
    });
}
function onlyOne(checkbox) {
    // this will deselect all other checkboxes in the form
    var checkboxes = document.getElementsByName("check");
    var checkedCheckboxes = document.querySelectorAll("input[name='check']:checked");
    if (checkedCheckboxes.length > 0) {
        checkboxes.forEach(item => {
            if (item !== checkbox) {
                item.checked = false;
            }
        });
        return true;
    } else {
        //if no boxes are checked it will return to "franchise" mode
        specialtyHide([]);
        return false;
    }
}
function specialtyHide(indication) {
    let specialtyList = document.querySelectorAll(".specialty .validateDdl .option");
    //this will hide all options from the indication in the dropdown
    specialtyList.forEach(option => {
        if (indication.includes(option.innerText)) {
            option.classList.add("hidden");
        } else {
            option.classList.remove("hidden");
        }
    });
}

export default { init };
