function init() {
    onScrollTranscript(".tab-pane.transcripts");
    onScrollTranscript(".tab-pane.transcripts .tab-pane");
    onScrollTranscript("#thumbs-injection .pane-wrapper");
}

export default { init };

function onScrollTranscript(element) {
    $(element).on("scroll", function() {
        if (this.scrollTop + this.offsetHeight + 1 >= this.scrollHeight) {
            $(this)
                .parent()
                .parent()
                .addClass("scroll-to-bottom");
        } else {
            $(this)
                .parent()
                .parent()
                .removeClass("scroll-to-bottom");
        }
    });
}
