function findConfirmationField(a, b, d) {
    let errorClass = "hasError";
    var c = true;
    $(".anwser").each(function() {
        var g = $(this).find(":input");
        if (g.attr("data-confirmation") == d && $(this).attr("data-type") == "FormTextBox" && a == $(this).attr("data-questionid")) {
            if (b != "" && g.val().trim() != "") {
                if (b != g.val().trim()) {
                    let f = "confirmLb" + g.attr("data-index");
                    $("#" + f).removeAttr("hidden");
                    g.addClass(errorClass);
                    c = false;
                    return c;
                } else {
                    g.removeClass(errorClass);
                    let f = "confirmLb" + g.attr("data-index");
                    $("#" + f).attr("hidden", g);
                }
            }
        }
    });
    return c;
}

export default findConfirmationField;
