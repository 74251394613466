import formQuestionsAreValid from "./formQuestionsAreValid";
import sendFormData from "./sendFormData";
import showSuccessMessage from "./showSuccessMessage";
import postSubmitChange from "./postSubmitChange";
import { validateFormTextBoxOnFocusOut, validateFormTextAreaOnFocusOut, validateFormDate, validateFormDropdownlistOnchange, validateFormCheckboxOnchange, validateFormRadioButtonOnchange } from "./validations";
import getParameterByName from "./getParameterByName";
import createCookie from "../../../utils/createCookie";
function initialiseForm() {
    if (!$(".dupixent-hcp-request").length) {
        return;
    }
    $(".text-component.aos-item.aos-init").attr("hidden", true);
    $(".txtDate").each(function() {
        $(this).datepicker({
            onClose: function() {
                $(this).focusout();
            }
        });
    });
    $("#btnDynamicFormSubmit").on("click", function(b) {
        if (formQuestionsAreValid("0", "0")) {
            sendFormData(b, "0", "0");
            showSuccessMessage();
            postSubmitChange();
        } else {
            b.preventDefault();
            $("#generalError").removeAttr("hidden");
            $("html, body").animate({ scrollTop: 0 }, 800);
        }
    });
    $(".txtBox").each(function() {
        $(this).focusout(function() {
            var b = $(this).parents(".anwser");
            var c = b.find(":input");
            if (!c.is("#zip")) {
                validateFormTextBoxOnFocusOut($(this));
            }
        });
    });
    $(".txtArea").each(function() {
        $(this).focusout(function() {
            validateFormTextAreaOnFocusOut($(this));
        });
    });
    $(".txtDate").each(function() {
        $(this).focusout(function() {
            validateFormDate($(this));
        });
    });
    $(".validateDdl").each(function() {
        $(this).focusout(function() {
            validateFormDropdownlistOnchange($(this));
        });
    });
    $(".validateDdl").each(function() {
        $(this).change(function() {
            validateFormDropdownlistOnchange($(this));
        });
    });
    $(".validateCb").each(function() {
        $(this).change(function() {
            validateFormCheckboxOnchange($(this));
        });
    });
    $(".validateRbtn").each(function() {
        $(this).change(function() {
            validateFormRadioButtonOnchange($(this));
        });
    });
    var a = getParameterByName("moc");
    if (a != "" && a != null) {
        createCookie("QuerySring__MOC", a);
    }
}

export default initialiseForm;
