function elemIsInViewport(elem) {
    const bounding = elem.getBoundingClientRect();
    return bounding.top >= 0 && bounding.left >= 0 && bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) && bounding.right <= (window.innerWidth || document.documentElement.clientWidth);
}
class ResultPercent {
    constructor() {
        this.resultsPercent = document.querySelectorAll(".results-percent");
        this.init();
    }

    init() {
        const $this = this;
        if ($this.resultsPercent.length) {
            $this.resultsPercent.forEach(node => {
                let animated = false;

                window.addEventListener("scroll", function() {
                    if (!animated) {
                        if (elemIsInViewport(node)) {
                            animated = true;
                            node.classList.add("inview");
                        }
                    }
                });
            });
            const eventScroll = new Event("scroll");
            window.dispatchEvent(eventScroll);
        }
    }
}

export default ResultPercent;
